module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"manpenaloza","short_name":"Manuel P.","start_url":"/manpenaloza/","background_color":"#8ebebc","theme_color":"#8ebebc","display":"browser","icon":"src/assets/img/website-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"70fd50585331c0dc3589869db0603c35"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138398009-3","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"cookieDomain":"manpenaloza.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
